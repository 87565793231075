//imports
import {sk_menu_click_handler, sk_menu_excerpt_on_hover} from "./modules/menu.js"
import {sk_wt_add_decription} from "./modules/wt.js"
import {sk_usps_height, sk_faq_height} from "./modules/usps.js"
import {sk_add_slider_navigation} from "./modules/slider.js"

//Define Variables



// Misc
const SKHeader = document.querySelector('#sk-header')
const SKFooter = document.querySelector('#sk-footer')

document.body.setAttribute('style', '--sk-menu-height:' + SKHeader.clientHeight + 'px;--sk-footer-height:' + SKFooter.clientHeight + 'px;')

// Mouse Event Handler
document.body.addEventListener('click', (e) => {

    // Controls classes on body element for Menu handling
    sk_menu_click_handler(e)
})

// Shows Description of Menu Item in Info Box
sk_menu_excerpt_on_hover()

// Poppulates the Work Together Menu with Descriptions
sk_wt_add_decription()

// Calculates Element Heights for USPs and FAQ Items
sk_usps_height()
sk_faq_height()

// Initiate Slider
sk_add_slider_navigation()